<template>
  <div class="p-4 history-modal small">
    <table class="table table-hover table-borderless">
      <thead>
        <tr>
          <th width="10%">Level</th>
          <th>Description</th>
          <th width="15%" v-if="user.role != 'Customer'">Reponse Time</th>
          <th width="15%" v-if="user.role != 'Customer'">Resolution Time</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Critical</td>
          <td>Produces an <b>emergency situation</b> in which Covered Software is inoperable, produces incorrect results, or fails catastrophically.</td>
          <td v-if="user.role != 'Customer'">Within two (2) hours</td>
          <td v-if="user.role != 'Customer'">Within four (4) hours</td>
        </tr>
        <tr>
          <td>Major</td>
          <td>Produces a <b>detrimental situation</b> in which performance (throughput or response) of the Covered Software degrades substantially under reasonable loads, such that there is a severe impact on use; the Covered Software is usable, but materially incomplete; one or more mainline functions or commands is inoperable; or the use is otherwise significantly impacted.</td>
          <td v-if="user.role != 'Customer'">Within four (4) hours</td>
          <td v-if="user.role != 'Customer'">Within twenty-four (24) hours</td>
        </tr>
        <tr>
          <td>Moderate</td>
          <td>Produces an <b>inconvenient situation</b> in which the Covered Software is usable, but does not provide a function in the most convenient or expeditious manner, and the user suffers little to no significant impact.</td>
          <td v-if="user.role != 'Customer'">As agreed with the Client</td>
          <td v-if="user.role != 'Customer'">Within two (2) weeks minimum (in line with the Sprint Cycle)</td>
        </tr>
        <tr>
          <td>Low</td>
          <td>Produces a <b>noticeable situation</b> in which the use is affected in some way which is reasonably correctable by a documentation change or by a future, regular release from iRipple.</td>
          <td v-if="user.role != 'Customer'">As agreed with the Client</td>
          <td v-if="user.role != 'Customer'">As agreed with the Client</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    props: {
      user: Object
    }
  }
</script>
