<template>
  <div>
    <div class="title-path">
      <a href="/">Home</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a href="/tickets">Tickets</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a href="/tickets/new">New</a>
    </div>
    <div class="row">
      <div class="col-md-8 pt-3 px-4">
        <h6 class="text-header">Issue title</h6>
        <editor
          v-model="ticketForm.issue"
          v-if="formControl.issue"
          class="mb-4 border-radius-0 form-plain"
          placeholder="Write a title for your ticket."
        >
        </editor>
        <h6 class="text-header float-left">Description</h6>
        <div class="float-right" style="border: 0px" v-tooltip="formControl.simpleEditor ? `Toggle advance editor` : `Toggle basic editor`" v-on:click="toggleEditor()">
          <div class="btn btn-sm text-light bg-secondary" style="border: 0px"><i class="fas fa-keyboard"></i></div>
        </div>
        <br><br>
        <div class="mb-4 tox-tinymce-rounded-border" style="margin-top: -10px;">
          <editor
            v-model="ticketForm.description"
            v-if="formControl.simpleEditor"
            class="mb-4 border-radius-0 form-plain"
            placeholder="Describe your concern or issue here."
          >
          </editor>
          <div v-else>
            <QuillEditor 
              v-model="ticketForm.description"
              class="mb-4 border-radius-0 form-plain"
              placeholder="Describe your concern or issue here."
            />
          </div>
          <!-- <tiny-editor
            v-else
            v-model="ticketForm.description"
            :api-key="this.$tinyMCEAPIKey"
            placeholder="Describe your concern or issue here."
            :init="{
              height: 500,
              menubar: true,
              menu: {
                file: { title: 'File', items: 'preview | print | help' },
                help: { title: 'Help', items: [] }
              },
              font_formats:
    `Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Lato=lato; Oswald=oswald; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats`,
              content_style: `@import url('https://fonts.googleapis.com/css2?family=Lato'); body { font-family: 'Lato' }`,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              fontsize_formats: '8pt 10pt 11pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
              lineheight_formats: '1 1.1 1.2 1.3 1.4 1.5 2',
              toolbar:
                'undo redo | fontselect fontsizeselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | link image | insertfile | \
                bullist numlist outdent indent | removeformat'
            }"
          /> -->
        </div>
        <div v-if="$getTagDetails(this.ticketForm.issueClassification, 'forms').length > 0">
          <div class="row no-gutters my-4" v-for="form in ticketForm.issueClassification.record.forms" v-bind:key="form.key">
            <h6 class="text-dark text-header">{{form.name}}</h6>
            <input type="text" class="form-control" :placeholder="form.name" v-model="form.value" v-if="form.type === 'text'">
            <input type="number" class="form-control" :placeholder="form.name" v-model="form.value" v-if="form.type === 'number'">
            <textarea rows="3" class="form-control" :placeholder="form.name" v-model="form.value" v-if="form.type === 'textarea'"></textarea>
            <ModelSelect
              v-model="form.value"
              placeholder="Field type"
              :options="$buildOptions(form.options)"
              v-if="form.type === 'select'"
            >
            </ModelSelect>
          </div>
          <br>
        </div>
      </div>
      <div class="col-md-4 p-3 px-4">
        <h6 class="text-header">Client</h6>
        <ModelSelect
          v-model="ticketForm.client"
          placeholder="Select client"
          :options="options.clients"
        >
        </ModelSelect>
        <br>
        <h6 class="text-header">Issue Classification</h6>
        <ModelSelect
          v-model="ticketForm.issueClassification"
          placeholder="Select issue classification"
          :options="filterTags(options.issueClassifications)"
          :isDisabled="!this.ticketForm.client.value"
        >
        </ModelSelect>
        <br>
        <h6 class="text-header">
          Severity &nbsp; <i class="fas fa-info-circle cursor-pointer text-dark" v-on:click="toggleSeverityGuide()"/>
        </h6>
        <ModelSelect
          v-model="ticketForm.priority"
          placeholder="Select severity"
          :options="options.priorities"
        >
        </ModelSelect>
        <br>
        <h6 class="text-header">Attachments</h6>
        <div class="attachment-list new-ticket">
          <input class="d-none" type="file" ref="file" multiple v-on:change="addAttachment" />
          <div class="attachment-item" v-on:click="$refs.file.click()">
            <i class="col-2 fas fa-plus text-escalated"></i>
          </div>
          <div class="attachment-item mb-2" v-for="attachment in ticketForm.attachments" v-bind:key="attachment.id" v-on:click="removeAttachment(attachment)" v-tooltip="attachmentName(attachment)">
            <i class="col-2 fas" v-bind:class="getFileIcon(attachment)"></i>
          </div>
        </div>
        <div v-if="ticketNumber && ticketForm.original_attachments.length > 0" class="small cursor-pointer text-secondary" v-tooltip="ticketForm.original_attachments.map(r => r.filename).join(', ')">
          With {{ticketForm.original_attachments.length}} attachments from {{ticketNumber}}
        </div>
        <br>
        <div v-if="formControl.settings">
          <h6 class="text-header">Auto close ticket after <span class="text-cool" v-html="ticketForm.settings.autoClose"></span> days</h6>
          <ModelSelect
            v-model="ticketForm.settings.autoClose"
            placeholder="Select duration limit"
            :options="options.autoClose"
          >
          </ModelSelect>
          <br>
        </div>
        <div class="form-control btn btn-primary p-2" v-on:click="createNewTicket">
          <i class="btn fas fa-save p-0 text-light mb-1"></i>&nbsp;
          Create ticket
        </div>
        <br>
      </div>
    </div>
    <modal name="severity-guide" :transition="`fade`" :width="`80%`" :height="`auto`" :scrollable="true">
      <SeverityGuide :user="user"/>
    </modal>
  </div>
</template>

<script>
  import { ModelSelect } from 'vue-search-select'
  import SeverityGuide from './TicketModals/SeverityGuide.vue'
  import QuillEditor from "../../components/Quills/QuillEditor.vue"

  export default {
    components: {
      ModelSelect,
      SeverityGuide,
      QuillEditor
    },
    data() {
      return {
        editorContent: '',
        user: this.$userData ? this.$userData.user : {},
        ticketNumber: this.$route.query._orig,
        formControl: {
          issue: true,
          description: true,
          issueClassification: true,
          client: !(this.$userData.user.role === 'Customer'),
          attachments: true,
          settings: !(this.$userData.user.role === 'Customer'),
          simpleEditor: false
        },
        ticketForm: {
          issue: '',
          description: this.$defaultTicketTemplate(this.$userData ? this.$userData.user : {}),
          issueClassification: {},
          client: this.$userData.user.role === 'Customer' ? {
            key: this.$userData.user.client._id,
            text: this.$userData.user.client.name,
            value: this.$userData.user.client._id,
            record: this.$userData.user.client
          } : {},
          priority: '',
          original_attachments: [],
          attachments: [],
          settings: {
            autoClose: 7
          }
        },
        options: {
          clients: [],
          issueClassifications: this.$issueClassifications,
          priorities: this.$priorities,
          autoClose: this.$autoClose,
        }
      }
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      async getClients(){
        this.loader(true);
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/clients`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.options.clients = response.data.clients.map(r => {
            return { key: r._id, text: r.name, value: r._id, record: r }
          })
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      async copyTicket(){
        this.loader(true)
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/ticket?ticketNumber=${this.ticketNumber}`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          let ticket = response.data.ticket
          this.$console(ticket)
          this.$console(this.options)
          if(ticket){
            this.ticketForm = {
              issue: ticket.issue,
              description: ticket.description,
              issueClassification: this.options.issueClassifications.find(r => r.value == ticket.issueClassification),
              client: this.options.clients.find(r => r.value == ticket.client._id),
              attachments: [],
              original_attachments: ticket.attachments || [],
              settings: ticket.settings,
              forms: ticket.forms,
              priority: (this.options.priorities.find(r => r.value == ticket.priority) || {}).value
            }
            if(this.ticketForm.issueClassification)
              this.ticketForm.issueClassification.record.forms = ticket.forms
          }
          this.$console(this.ticketForm)
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      createNewTicket(){
        var files = this.ticketForm.attachments
        var attachments = []
        for (let i = 0; i < files.length; i++) {
          const attachment = new FormData()
          attachment.append('file', files[i])
          attachments.push(attachment)
        }
        let data = {
          issue: this.$stripHtml(this.ticketForm.issue),
          description: this.ticketForm.description,
          client: this.ticketForm.client.record,
          issueClassification: this.ticketForm.issueClassification.value,
          priority: this.ticketForm.priority,
          forms: this.$getTagDetails(this.ticketForm.issueClassification, 'forms'),
          settings: this.ticketForm.settings,
          isActive: true,
          createdBy: this.user,
          customer: this.user.role === 'Customer' ? this.user : undefined,
          manager: this.user.role === 'Manager' ? this.user : undefined,
          agent: undefined,
          status: 'pending',
          attachments: []
        }
        let errors = this.validateTicket(data)
        if(errors.length > 0) {
          errors.forEach(error => {
            this.$alertify({
              group: 'notification',
              title: 'Create ticket',
              type: 'warn',
              text: error
            })
          });
        } else {
          this.loader(true)
          this.$uploadFiles(attachments).then(responses => {
            this.loader(false)
            data.attachments = responses.map(r => r.data.filePath)
            data.attachments = data.attachments.concat(this.ticketForm.original_attachments)
            this.submitNewTicket(data)
          }).catch(errors => {
            this.loader(false)
            console.log(errors)
            this.$alertify({
              group: 'notification',
              title: 'File attachment',
              type: 'warn',
              text: 'We encountered a problem while uploading the attachments. Please try again.'
            })
          })
        }
      },
      submitNewTicket: async function(data){
        this.loader(true)
        try {
          const response = await this.$http.post(
            `${this.$apiEndpoint}/v1/tickets/new`,
              {ticket: data},
              {
                headers: {
                  'Authorization': this.$userData.authToken,
                  'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          let ticket = response.data.ticket;
          if(ticket && ticket._id){
            this.$alertify({
              group: 'notification',
              title: `Ticket ${ticket.ticketNumber} - ${ticket.issue}`,
              type: 'success',
              text: `Your reported issue "${ticket.issue}" was successfully created with the ticket number ${ticket.ticketNumber}`
            })
            this.$router.push(`/tickets/${ticket.ticketNumber}`)
          }
          else{
            this.$alertify({
              group: 'notification',
              title: 'Create failed',
              type: 'warn',
              text: 'We encountered a problem while saving your ticket. Please try again.'
            })
          }
          this.loader(false)
        } catch (error) {
          this.loader(false)
          console.log(error)
        }
      },
      validateTicket: function(data){
        let errors = []
        let size = (new TextEncoder().encode(JSON.stringify(data)).length) / 1024 / 1024

        if(size > 40)
          errors.push('API payload exceeds the limit. If there are images pasted on the ticket description, please consider adding them as links or attachments.')
        if(!data.issue || data.issue.trim() === '')
          errors.push('Issue title cannot be empty')
        if(!data.description || this.$stripHtml(data.description).trim() === '')
          errors.push('Description cannot be empty')
        if(!data.client || data.client === {})
          errors.push('Client cannot be empty')
        if(!data.issueClassification || data.issueClassification.trim() === '')
          errors.push('Issue classification cannot be empty')
        if(!data.priority || data.priority.trim() === '')
          errors.push('Severity cannot be empty')
        data.forms.forEach(form => {
          if(form.required && (form.value === undefined || `${form.value}`.trim() === ''))
            errors.push(`${form.name} cannot be empty`)
        });
        return errors.reverse()
      },
      attachmentName: function(attachment){
        return attachment.originalname || attachment.filename || attachment.name || ''
      },
      fileExt: function(filename){
        return filename.split('.').pop().toLowerCase()
      },
      getFileIcon: function(attachment){
        switch(this.fileExt(this.attachmentName(attachment))){
          case 'doc': case 'docx':
            return 'fa-file-word'
          case 'ppt': case 'pptx':
            return 'fa-file-powerpoint'
          case 'pdf':
            return 'fa-file-pdf'
          case 'xls': case 'xlsx':
            return 'fa-file-excel'
          case 'csv':
            return 'fa-file-csv'
          case 'zip': case 'rar': case '7z':
            return 'fa-file-archive'
          case 'jpeg': case 'jpg': case 'png': case 'gif': case 'tiff': case 'bmp': case 'img': case 'ico': case 'svg': case 'eps': case 'raw':
            return 'fa-file-image'
          case 'mp4': case 'mov': case 'avi': case 'flv': case 'wmv': case 'swf': case 'mkv': case 'mpeg':
            return 'fa-file-video'
          case 'txt': case 'html': case 'css': case 'js': case 'vue': case 'ts': case 'xml':
            return 'fa-file-code'
          default:
            return 'fa-file'
        }
      },
      addAttachment: function(event){
        var files = event.target.files;
        var invalidFiles = []
        for(var i = 0; i < files.length; i++){
          if (files[i].size <= 26214400){
            this.ticketForm.attachments = this.ticketForm.attachments.filter(s => s.name !== files[i].name)
            this.ticketForm.attachments.push(files[i])
          }
          else
            invalidFiles.push(files[i].name)
        }
        invalidFiles.forEach(filename => {
          this.$alertify({
            group: 'notification',
            title: 'File attachment',
            type: 'warn',
            text: `${filename} exceeds 25mb`
          })
        });
      },
      removeAttachment: function(file){
        this.ticketForm.attachments = this.ticketForm.attachments.filter(s => s.name !== file.name)
        this.$alertify({
          group: 'notification',
          title: 'File attachment',
          type: 'info',
          text: `${file.name} was removed`
        })
      },
      filterTags: function(issueClassifications){
        return issueClassifications.filter(r => (
          this.$getTagDetails(r, 'clients').length === 0 ||
          this.$getTagDetails(r, 'clients').filter(q => q._id === this.ticketForm.client.value).length > 0
        ))
      },
      toggleSeverityGuide: function(){
        this.$modal.toggle('severity-guide');
      },
      toggleEditor: function(){
        this.formControl.simpleEditor = !this.formControl.simpleEditor;
      }
    },
    mounted: function() {
      document.title = `New ticket | iRipple Helpdesk`
      this.getClients()
      if(this.ticketNumber)
        this.copyTicket(true)
    },
    filters: {
      convertSize: function(a, b = 2){
        if(0===a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return parseFloat((a/Math.pow(1024,d)).toFixed(c))+" "+["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d]
      }
    },
    watch: {
      'ticketForm.client': function () {
        if(!this.filterTags(this.options.issueClassifications).map(r => r.value).includes(this.ticketForm.issueClassification.value))
          this.ticketForm.issueClassification = {}
      }
    }
  }
</script>
